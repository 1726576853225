export const xnsServiceName = document.location.hostname.endsWith('beta.xyo.network') || !document.location.hostname.endsWith('xyo.network')
  ? 'beta.xns.xyo.network'
  : 'xns.xyo.network'

export const xnsHostname = document.location.hostname.endsWith('beta.xyo.network') || !document.location.hostname.endsWith('xyo.network')
  ? 'beta.xyo.network'
  : 'xyo.network'

export const xnsServiceProtocol = xnsServiceName.endsWith('xyo.network')
  ? 'https'
  : 'http'

export const xnsServiceUrl = `${xnsServiceProtocol}://${xnsServiceName}`

export const xnsNameFromQuery = () => {
  const params = new URLSearchParams(document.location.search)
  return params.get('name')
}

export const getXnsName = () => {
  const location = document.location
  const xnsName = location.hostname.replace(`${xnsHostname}`, 'xyo')
  if (xnsName.split('.').length === 2) {
    // it has two parts, the second being 'xyo'
    return xnsName
  }
  return xnsNameFromQuery()
}

export const generateXnsQuery = (name: string, type: 'XYOA' | 'XYOH') => {
  return `${xnsServiceUrl}/queryXns?name=${name}&type=${type}`
}
