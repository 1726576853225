import type { Theme, ThemeOptions } from '@mui/material'
import { alpha } from '@mui/material'

export const overrideThemeOptions = (theme: Theme): ThemeOptions => {
  return {
    breakpoints: {
      values: {
        lg: 1350,
        md: 900,
        sm: 600,
        xl: 1536,
        xs: 0,
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
            color: 'inherit',
          },
          standardError: { backgroundColor: alpha(theme.palette.error.main, 0.3), color: theme.palette.error.light },
          standardInfo: { backgroundColor: alpha(theme.palette.info.main, 0.3), color: theme.palette.info.light },
          standardSuccess: { backgroundColor: alpha(theme.palette.success.main, 0.3), color: theme.palette.success.light },
          standardWarning: { backgroundColor: alpha(theme.palette.warning.main, 0.3), color: theme.palette.warning.light },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorError: { backgroundColor: alpha(theme.palette.error.main, 0.3) },
          colorInfo: { backgroundColor: alpha(theme.palette.info.main, 0.3) },
          colorPrimary: { backgroundColor: alpha(theme.palette.primary.main, 0.3) },
          colorSecondary: { backgroundColor: alpha(theme.palette.secondary.main, 0.3) },
          colorSuccess: { backgroundColor: alpha(theme.palette.success.main, 0.3) },
          colorWarning: { backgroundColor: alpha(theme.palette.warning.main, 0.3) },
        },
      },
      MuiContainer: { styleOverrides: { root: { maxWidth: 'xl' } } },
    },
    shape: { borderRadius: 4 },
    spacing: 12,
    typography: {
      fontFamily: '"Lexend Deca", sans-serif',
      body1: {
        fontSize: '1rem',
        fontWeight: 300,
      },
      button: {
        fontSize: '1rem',
        fontWeight: 300,
        textTransform: 'capitalize',
      },
      fontWeightBold: 700,
      fontWeightLight: 300,
      fontWeightMedium: 400,
      fontWeightRegular: 400,
      h1: { fontSize: '3.2rem' },
      h2: { fontSize: '2.7rem' },
      h3: { fontSize: '2.24rem' },
      h4: { fontSize: '2rem' },
      h5: { fontSize: '1.5rem' },
      h6: { fontSize: '1.25rem' },
      subtitle1: {
        opacity: '50%',
        textTransform: 'uppercase',
      },
      subtitle2: { opacity: '50%' },
    },
  }
}
